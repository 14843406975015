<template>
  <div class="healthIQ">
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col class="tabbar">
          <div class="">
            <el-input size="medium" class="demo-input-label" placeholder="请输入内容" maxlength="10" v-model="keyWord" clearable></el-input>
            <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-search" @click="searchClick">搜索</el-button>
            <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-plus" @click="addInfo()">添加</el-button>
          </div>
          <div style="display: flex">
            <!-- <el-upload action="/hiq-admin/bhiq/excel/healthIQExcelImport" accept=".xlsx, .xls" ref="upload" :on-success="onSuccess" limit="1" show-file-list="false">
              <el-button class="demo-input-label" type="primary" size="medium" style="" icon="el-icon-upload2">点击上传</el-button>
            </el-upload> -->
            <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-download" @click="healthExcelExportFile">导出</el-button>
          </div>
        </el-col>
        <el-col class="tabbar" style="margin-top: 20px">
          <div class="">
            <el-cascader collapse-tags v-model="projectId" :options="dictList" :props="props" @change="handleChange" clearable></el-cascader>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <div class="main">
      <el-table :data="records" border style="width: 98%" v-loading="isLoading" :height="innerHeight" class="loading">
        <!-- 展开列 -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-tag v-for="item1 in scope.row.questionDicts" class="tags" :key="item1.id">{{ item1.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column type="index" width="100"></el-table-column>
        <el-table-column prop="question" label="题目" width="300" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="answer" label="答案" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="question" label="是否正确" width="180" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.answerFlag == '1'">正确</el-tag>
            <el-tag type="warning" v-else>错误</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="addInfo(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeById(scope.row)">删除</el-button>
          </template>
        </el-table-column>
        <template slot="opt">></template>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>
    <!-- 修改用户的对话框 -->
    <el-dialog :title="saveHealthIQ.id ? '编辑题目' : '添加题目'" :visible.sync="setRoleDialogVisible" width="50%" @close="editDialogClosed">
      <el-form :model="saveHealthIQ" ref="editFormRef" :rules="saveHealthIQRules" label-width="70px">
        <el-form-item label="题目" prop="question">
          <el-input v-model="saveHealthIQ.question"></el-input>
        </el-form-item>
        <el-form-item label="是否正确">
          <el-radio v-model="saveHealthIQ.answerFlag" :label="1">正确</el-radio>
          <el-radio v-model="saveHealthIQ.answerFlag" :label="2">错误</el-radio>
        </el-form-item>
        <el-form-item label="答案" prop="answer">
          <el-input v-model="saveHealthIQ.answer" type="textarea"></el-input>
        </el-form-item>

        <el-form-item label="标签" prop="parentId">
          <el-tree
            :key="defaultChecked"
            ref="tagTree"
            :data="cascaderDictList"
            highlight-current
            show-checkbox
            node-key="id"
            default-expand-all
            :default-checked-keys="defaultChecked"
            :props="{
              children: 'children',
              label: 'name',
            }"
          ></el-tree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination/Index.vue'
import { Decrypt } from '@/utils/common/encryp'
import { commonMixin } from '@/utils/mixin/index.js'
import Cookies from 'js-cookie'
const saveHealthIQ = {
  answer: '',
  answerFlag: 1,
  question: '',
  status: '1',
  dictIds: [],
}
export default {
  name: 'AdminDemoIndex',
  components: {
    pagination,
  },
  data() {
    return {
      props: {
        label: 'name',
        value: 'id',
        multiple: true,
        emitPath: true,
      },
      params: { pageNum: 1, pageSize: 10, keyWord: '', tags: [] },
      records: [],
      total: 0,
      isIndeterminate: false,
      saveHealthIQ: JSON.parse(JSON.stringify(saveHealthIQ)),
      tree: [],
      addDialog: true,
      // 控制分配角色对话框的显示与隐藏
      setRoleDialogVisible: false,
      cascaderDictList: [],
      defaultChecked: [],
      isLoading: false,
      saveHealthIQRules: {
        answer: [{ required: true, message: '请输入题目', trigger: 'blur' }],
        question: [{ required: true, message: '请输入答案', trigger: 'blur' }],
      },
      keyWord: '',
    }
  },
  mixins: [commonMixin],
  mounted() {
    this.getData()
    this.getTagsList()
  },
  methods: {
    handleChange(val) {
      this.params.tags = val.map((item) => item[item.length - 1])
      console.log(this.params.tags)
    },
    async getTagsList() {
      const res = await this.$API.dict.get({ dictType: 1 })
      if (res.status !== 200) return ''
      this.dictList = res.data
      this.cascaderDictList = this.dictList
    },
    onSuccess(res) {
      this.importDataText = '导入数据'
      this.importDataIcon = 'el-icon-upload2'
      if (res.status == 200) {
        this.$message.success(res.msg)
        this.$refs.upload.clearFiles()
        this.getData()
      } else {
        this.$message.error(res.msg)
        this.$refs.upload.clearFiles()
      }
    },
    async healthExcelExportFile() {
      const { keyWord } = this.params
      const res = await this.$API.healthIQ.ExportFile({ keyWord })
      const link = document.createElement('a')
      let blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.download = 'healthIQ.xlsx'
      document.body.appendChild(link)
      link.click()
    },
    setRoleDialogClosed() {},
    async saveInfo() {
      this.$refs['editFormRef'].validate(async (valid) => {
        if (valid) {
          const tagList = this.$refs.tagTree.getCheckedNodes(true)
          this.saveHealthIQ.dictIds = tagList.map((item) => {
            return item.id
          })
          let res
          if (this.saveHealthIQ.id) {
            res = await this.$API.healthIQ.update(this.saveHealthIQ)
          } else {
            res = await this.$API.healthIQ.add(this.saveHealthIQ)
          }

          if (res.status !== 200) return this.$message.error('获取用户列表失败！')
          this.setRoleDialogVisible = false
          this.$message.success(res.msg)
          this.params.pageNum = 1
          this.params.keyWord = ''
          this.getData()
        } else {
          return this.$message.error('必填项不能为空')
        }
      })
    },
    async removeById(item) {
      const { id, questionNo } = item
      let params = {
        id,
        questionNo,
      }
      // 弹框询问用户是否删除数据
      const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).catch((err) => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const res = await this.$API.healthIQ.delete(params)
      if (res.status !== 200) {
        return this.$message.error('删除失败！')
      }
      this.$message.success('删除成功！')
      this.getData()
    },
    addInfo(item = {}) {
      this.defaultChecked = []
      this.setRoleDialogVisible = true
      if (item.id) {
        this.saveHealthIQ = item
        let defaultChecked = item.questionDicts && JSON.parse(JSON.stringify(item.questionDicts))
        defaultChecked =
          defaultChecked.map((item) => {
            return item.dictId
          }) || []
        this.$nextTick(() => {
          this.$refs.tagTree.setCheckedKeys([])
          this.defaultChecked = defaultChecked
        })
      } else {
        this.saveHealthIQ = JSON.parse(JSON.stringify(saveHealthIQ))
      }
    },
    searchClick() {
      this.params.keyWord = this.keyWord
      this.params.pageNum = 1
      this.getData()
    },
    handleCheckAllChange() {},
    async getData() {
      try {
        this.isLoading = true
        const res = await this.$API.healthIQ.get(this.params)
        this.isLoading = false
        if (res.status !== 200) return this.$message.error(res.msg)
        const { records, total } = res.data
        this.records = records
        this.total = +total
      } catch (error) {
        this.$message.error('请求失败')
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}

// .el-cascader {
//   width: 100%;
// }
.tabbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

// .el-breadcrumb {
//   margin-bottom: 20px;
// }
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}

.el-tag {
  margin: 7px;
  display: inline-block !important;
}
</style>
